<template>
  <div>
    <v-text-field
      id="estracoes-geo-perdas-historico-search"
      v-model="search"
      append-icon="mdi-magnify"
      class="ml-auto mt-8 break-search"
      label="Procurar"
      hide-details
      single-line
      style="max-width: 250px"
    />
    <v-data-table
      class="extracoes-geo-perdas-table"
      :headers="headers"
      :items="items"
      :search.sync="search"
      :mobile-breakpoint="800"
      :loading="loading"
      :hide-default-header="loading"
      :hide-default-footer="loading"
      no-data-text="Nenhuma Extração GeoPerdas encontrada"
    >
      <template v-slot:[`item.status`]="{ item }">
        <general-status :status="item.status" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          id="baixar-extracao"
          min-width="0"
          class="px-1"
          fab
          icon
          x-small
          :loading="downloadProgressData[item.id]"
          :disabled="item.status === 'SUCESSO' ? false : true"
          @click="downloadExtracao(item)"
        >
          <v-icon medium> mdi-download </v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="action-more-conciliacao"
              color="white"
              class="ml-1"
              height="22px"
              width="22px"
              fab
              x-small
              elevation="1"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="
                (dialogCreationLog = true),
                  (dialogCreationLogData = {
                    username: item.usuario,
                    created: item.criado_em
                  })
              "
            >
              <v-list-item-title>
                <v-icon small> mdi-table-search </v-icon>
                Log
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
  </div>
</template>

<script>
import ExtracoesGeoPerdasService from '@/services/ExtracoesGeoPerdasService';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  mixins: [refreshDataMixins],

  components: {
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue'),
    DialogCreationLog: () => import('@/components/general/DialogCreationLog')
  },

  mounted() {
    this.refreshData(this.getAllExtracoes);
  },

  data() {
    return {
      headers: [
        {
          text: '#',
          value: 'id'
        },
        {
          text: 'Título',
          value: 'titulo'
        },
        {
          text: 'Tabela',
          value: 'tabela'
        },
        {
          text: 'Servidor',
          value: 'servidor'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
          class: 'pl-4'
        }
      ],
      items: [],
      search: null,
      loading: false,
      secondsToRefresh: 30,
      downloadProgressData: [],
      dialogCreationLog: false,
      dialogCreationLogData: {}
    };
  },

  methods: {
    getAllExtracoes() {
      ExtracoesGeoPerdasService.getAllExtracoes()
        .then(({ data }) => {
          this.items = data.data;
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error('Falha ao buscar as Extrações GeoPerdas.', '', {
            position: 'topRight'
          });
        });
    },
    downloadExtracao(item) {
      this.downloadProgressData[item.id] = true;
      ExtracoesGeoPerdasService.baixarExtracao(item.id, {
        timeout: 60 * 60 * 1000
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `extracao-geo-perdas-${item.id}.zip`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download da extração.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.downloadProgressData[item.id] = false));
    }
  }
};
</script>
