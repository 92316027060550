var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticClass:"ml-auto mt-8 break-search",staticStyle:{"max-width":"250px"},attrs:{"id":"estracoes-geo-perdas-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"extracoes-geo-perdas-table",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhuma Extração GeoPerdas encontrada"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",attrs:{"id":"baixar-extracao","min-width":"0","fab":"","icon":"","x-small":"","loading":_vm.downloadProgressData[item.id],"disabled":item.status === 'SUCESSO' ? false : true},on:{"click":function($event){return _vm.downloadExtracao(item)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-download ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more-conciliacao","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){(_vm.dialogCreationLog = true),
                (_vm.dialogCreationLogData = {
                  username: item.usuario,
                  created: item.criado_em
                })}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-table-search ")]),_vm._v(" Log ")],1)],1)],1)],1)]}}],null,true)}),_c('dialog-creation-log',{attrs:{"dialog":_vm.dialogCreationLog,"item":_vm.dialogCreationLogData},on:{"update:dialog":function($event){_vm.dialogCreationLog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }